import { getCurrentEnvironment } from '@toasttab/current-environment'

/**
 * Gets the default font size (based on user settings)
 * see https://medium.com/@vamptvo/pixels-vs-ems-users-do-change-font-size-5cfb20831773
 */

export type BrowserPreferences = {
  fontSize: string
  pixelRatio: number
  zoom: number
  darkMode: boolean
  isScrollbarShowingByDefault: boolean
}

/**
 * Works by temporarily creating a div with a scrollbar and checking if the scrollWidth is different from the width
 *
 * If the system/OS does not show scrollbars always show scrollbars, the width will remain unchanged (until/unless the user scrolls or moves into scroll position)
 * If the scrollbars are always showing, the scrollWidth will be less than the width that we set (to account for scroll bar width)
 *
 * @returns true if the scrollbar is visible by default
 */
const getIsScrollBarVisibleByDefault = () => {
  const testWidth = 100
  const innerEl = document.createElement('div')
  innerEl.style.height = '200px'
  const containerEl = document.createElement('div')
  containerEl.style.height = '10px'
  containerEl.style.width = `${testWidth}px`
  containerEl.style.overflow = 'auto'

  containerEl.appendChild(innerEl)
  document.body.appendChild(containerEl)

  const isScrollbarVisible = containerEl.scrollWidth < testWidth

  document.body.removeChild(containerEl)

  return isScrollbarVisible
}

export const getBrowserPreferences = (): BrowserPreferences => {
  let fontSize = 'unknown'
  if (window.getComputedStyle) {
    // Ignore very old browsers.
    fontSize =
      window.getComputedStyle(document.documentElement)?.fontSize || 'unknown'
  }

  /*
  NOTES: 
  - zoom is not to be trusted for firefox (behaves strangely)
  - on iOS devices the only obvious way to zoom is this one (there is no obvious way to change just the text size)
  */
  const zoom = Math.round((window.outerWidth / window.innerWidth) * 100)

  /*
  NOTES: 
  - Chrome browser (on a Mac at least) has settings that are ignored in favour of the system settings (a bug perhaps)
  */
  const darkMode =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches

  const isScrollbarShowingByDefault = getIsScrollBarVisibleByDefault()

  const browserPreferences = {
    fontSize,
    pixelRatio: window.devicePixelRatio,
    zoom,
    darkMode,
    isScrollbarShowingByDefault
  }

  if (getCurrentEnvironment() === 'preprod') {
    console.log('browserPreferences', browserPreferences)
  }

  return browserPreferences
}
