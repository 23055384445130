import { SingleSpaCustomEventDetail } from 'single-spa'

// Defining these events here because they're not provided by single-spa's types
export enum SingleSPAEventName {
  BEFORE_APP_CHANGE = 'before-app-change',
  BEFORE_NO_APP_CHANGE = 'before-no-app-change',
  BEFORE_ROUTING = 'before-routing-event',
  BEFORE_MOUNT_ROUTING = 'before-mount-routing',
  BEFORE_FIRST_MOUNT = 'before-first-mount',
  FIRST_MOUNT = 'first-mount',
  APP_CHANGE = 'app-change',
  NO_APP_CHANGE = 'no-app-change',
  ROUTING_EVENT = 'routing-event'
}

export interface SingleSpaEvent extends Event {
  detail: SingleSpaCustomEventDetail
}
/**
 * addListener
 * Adds a listener callback to a single SPA event
 */
export function addListener(
  eventName: SingleSPAEventName,
  callback: (e: SingleSpaEvent) => void
) {
  const prefixedEvent = `single-spa:${eventName}`
  window.addEventListener(
    prefixedEvent,
    callback as EventListenerOrEventListenerObject
  )
  return () =>
    window.removeEventListener(
      prefixedEvent,
      callback as EventListenerOrEventListenerObject
    )
}
