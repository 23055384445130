import { registerApplication } from 'single-spa'
import { RegistrationFunc } from './registration'
import * as medaliaSurveySpa from '@local/medallia-survey-spa'
import { isToastUserRole } from '../utils'

export const medallia: RegistrationFunc = (globalCustomProps) => {
  const isToastInternalUser = isToastUserRole(
    globalCustomProps.auth?.userInfo.roles || []
  )

  registerApplication({
    name: 'medallia-survey-spa',
    activeWhen: ({ pathname }) =>
      !isToastInternalUser && pathname.startsWith('/restaurants/admin'),
    customProps: globalCustomProps,
    app: medaliaSurveySpa
  })
}
