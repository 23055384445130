import {
  identify,
  addEventProperties,
  addUserProperties
} from '@toasttab/use-heap'
import { GlobalCustomProps } from '@local/global-custom-props'
import {
  getBrowserPreferences,
  BrowserPreferences
} from './get-browser-preferences'
import {
  checkCustomerPermissions,
  CustomerPermissions
} from '@toasttab/check-permissions'
type UserProperties = BrowserPreferences & {
  isToastAdministrator?: boolean
  hasAccessToShop?: boolean
  email?: string
}

export function initializeHeapAnalytics({
  auth,
  restaurantInfo,
  featureFlags
}: GlobalCustomProps) {
  const hasProductPageAccess = Boolean(featureFlags?.['plg-119-product-page'])
  const userProperties: UserProperties = {
    ...getBrowserPreferences()
  }
  if (auth) {
    identify(auth.userInfo.guid)
    userProperties.isToastAdministrator = auth.userInfo.isToastAdministrator
    userProperties.email = auth.userInfo.email
    userProperties.hasAccessToShop = checkCustomerPermissions(
      auth.userInfo.customerPermissions,
      {
        checkType: 'all of',
        permissionBits: [CustomerPermissions.SHOP_ACCESS_BIT]
      }
    )
  }

  // This is not exposed directly via @toasttab/use-heap, but that package
  // does declare the types that let us know this is available on the window object
  if (window.heap) {
    window.heap.clearEventProperties()
  }

  addUserProperties(userProperties)
  addEventProperties({
    restaurant_guid: restaurantInfo?.restaurantGuid,
    restaurant_name: restaurantInfo?.restaurantName,
    management_set_guid: restaurantInfo?.managementSet?.guid,
    hasProductPageAccess: hasProductPageAccess.toString()
  })
}
