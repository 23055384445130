import {
  addListener,
  SingleSpaEvent,
  SingleSPAEventName
} from '@local/single-spa-events'
import { GlobalCustomProps } from '@local/global-custom-props'
import { type datadogRum } from '@datadog/browser-rum'

type DD_RUM = typeof datadogRum

export enum ActionType {
  BANQUET_SPA_MOUNTED = 'BANQUET_SPA_MOUNTED'
}

export function addAction(action: ActionType, metadata?: Record<string, any>) {
  getRUM()?.addAction?.(action, metadata)
}

function getRUM() {
  return (window as any)?.DD_RUM as DD_RUM | undefined
}

export function initializeSpaMountTracking(
  globalCustomProps: GlobalCustomProps
) {
  const isToastUser = globalCustomProps.auth?.userInfo?.isToastAdministrator
  addListener(SingleSPAEventName.APP_CHANGE, (event: SingleSpaEvent) => {
    const mountedSpas = event.detail.appsByNewStatus.MOUNTED
    if (mountedSpas.length > 0) {
      mountedSpas.forEach((spa) => {
        addAction(ActionType.BANQUET_SPA_MOUNTED, {
          isInternalUser: isToastUser,
          spaName: spa
        })
      })
    }
  })
}
