// RRA Subnav regex
const IS_ANALYTICS_OR_LEGACY =
  /\/restaurants\/admin\/(analytics|labor|reports|glcodes|schedule|export|insights|financial-insights)/
const IS_BASIC_INTERNAL = /\/restaurants\/toast\/admin\/render\/reports/
const IS_USERS_INTERNAL = /\/toast\/reports\/users/
const IS_FRAUD_ANALYTICS_INTERNAL = /\/fraudanalyticsadmin/
const IS_GUEST_COMPLIANCE_INTERNAL = /\/guestcompliance/

export const hasRRASubnav = (pathname: string) =>
  IS_ANALYTICS_OR_LEGACY.test(pathname) ||
  IS_BASIC_INTERNAL.test(pathname) ||
  IS_USERS_INTERNAL.test(pathname) ||
  IS_FRAUD_ANALYTICS_INTERNAL.test(pathname) ||
  IS_GUEST_COMPLIANCE_INTERNAL.test(pathname)
